.page {
  position: relative;

  .qr-code-container {
    top: 25%;
    position: absolute;
    left: 50%;
    width: 80%;

    .qr-code {
      position: relative;
      top: -50%;
      left: -37%;
    }
  }
}

.payByLinkFirstPage {
  height: 796px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  position: relative;

  color: #000000;

  user-select: none;

  .payByLinkFirstPage-container {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .payByLinkFirstPage-title {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 3.2rem 0;
      h1 {
        margin: 0;
        font-size: 3rem;
        font-family: 'colfax-regular';

        margin-right: 2rem;
      }

      img {
        width: 25%;
      }
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 199;
    }

    .canvas-container {
      top: 2rem;
      position: absolute !important;
    }

    .payByLinkFirstPage-border {
      // width: 500px;
      // height: 500px;
      border: 5px solid #4a72b5;
      border-radius: 20px;
      margin-top: 2rem;
      padding: 1rem;
      align-items: center;
    }

    .payByLinkFirstPage-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .payByLinkFirstPage-AlipayLogo {
      max-height: 30px;
      width: auto;
    }

    .payByLinkFirstPage-UnionPayLogo {
      object-fit: contain;
      max-height: 30px;
      width: auto;
    }

    .payByLinkFirstPage-UpopLogo {
      object-fit: contain;
      max-height: 30px;
      width: auto;
    }

    .payByLinkFirstPage-WeChatPayLogo {
      max-height: 30px;
      width: auto;
    }

    .payByLinkFirstPage-qrCode {
      width: 400px;
    }
  }

  .payByLinkFirstPage-footer {
    width: 50%;
    height: 50%;
    padding-top: 2rem;
    margin-top: 4rem;

    .payByLinkFirstPage-yedpayLogo {
      width: 150px;
      position: relative;
      bottom: -30%;
      right: -60%;
    }
  }
}
