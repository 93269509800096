.yedpay-form {
  background-color: #fff;
  background-image: url('../../../assets/img/registrationForm/background.png');
  background-position: top right;
  background-repeat: no-repeat;
  max-width: 578px;

  margin: 2rem auto;
  border-radius: 20px;

  .yedpay-form-body {
    min-height: 20vh;
    padding: 1.5rem;
  }
}
