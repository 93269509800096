@import './variables.scss';
:root {
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-border-color: transparent;
}

@import './loader.scss';
@import './fonts.scss';
@import './button.scss';
@import './animation-delay.scss';
@import './divider.scss';
@import './scrollbar.scss';
@import './cursor.scss';
@import './correctWidth.scss';
@import './border.scss';
@import './image.scss';
@import './modal.scss';
@import './height.scss';
@import './card.scss';
@import './ribbon.scss';

@import "../../../node_modules/bootstrap/scss/bootstrap";
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:disabled,
.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:focus,
.btn-success:disabled,
.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:disabled,
.btn-outline-primary:hover,
.btn-outline-primary:disabled {
  color: white !important;
}
