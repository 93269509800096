@import '../../../../assets/scss/variables';

.calender-header-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
    font-size: 1.3em;
  }
}

.calender-container {
  background-color: white;
  font-size: 0.8rem;

  width: auto;
  border-radius: 0 0 8px 8px;

  .calender-toggler {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .calender-year-button {
    cursor: pointer;
  }

  .calender-year-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    .calender-year {
      border-radius: 8px;
      margin: 5px;
    }

    .calender-year-selected {
      background-color: $primary;
      color: $white;
    }

    .calender-year-current {
      background-color: $success;
      color: $white;
    }

    .calender-year-normal {
      &:hover {
        background-color: $box-shadow;
      }
    }

    ul {
      padding: 0;
      width: 100%;
      li {
        text-align: center;
        padding: 0.2rem 0;
        list-style-type: none;
        cursor: pointer;
      }
    }
  }

  .calender-week-days-container {
    display: flex;
    justify-content: space-around;
    padding-top: 0.5rem;
  }

  .calender-title {
    background-color: $bg-light-white;
    padding: 0.1rem;
    color: $caption-text;

    button {
      font-size: 2em;
    }
  }

  .calender-date-grid {
    display: grid;
    grid-template: repeat(7, auto) / repeat(7, auto);

    .calender-cell {
      text-align: center;
      align-self: center;
      letter-spacing: 0.1rem;
      padding: 0.6rem 0.25rem;
      span {
        // padding: 0.4rem;
        border-radius: 8px;
      }
      user-select: none;
    }

    .calender-day {
      font-weight: 600;
      cursor: pointer;
      &:hover span {
        transition: all 0.4s ease-out;
        color: $primary;
        background: $box-shadow;
      }
    }

    .calender-day-current {
      span {
        padding: 0.4rem;
        color: #fff !important;
        background-color: $primary !important;
      }
    }

    .calender-day-today {
      span {
        padding: 0.4rem;
        color: $white !important;
        background: $success !important;
      }
    }

    .calender-day-bold {
      span {
        color: $text;
        font-weight: 500;
      }
    }
    .calender-day-fade {
      span {
        color: $caption-text;
        font-weight: 300;
      }
    }
  }
}
