@import '../../../assets/scss/variables.scss';
.filter-title {
  padding: 1rem;
  border-bottom: 2px solid $box-shadow;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 1.6em;
  }
}

.filter-container {
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  max-height: auto;

  .filter-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    flex: 1;
  }

  .collapsed {
    display: none;
  }

  .pointer {
    cursor: pointer;
  }
}
