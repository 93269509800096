.select-icon {
  height: 22px;
}
.gateway-selection {
  padding: 12px;
  border: 1px solid #d3d3df;
  margin-top: 24px;
  border-radius: 10px;
  cursor: pointer;
  background: #ffffff;
}

.gateway-info {
  img {
    margin-right: 12px;
  }
}

.gateway-selected {
  border-color: #4d7bf3;
  background-color: #4d7bf3;
  span {
    color: #ffffff;
  }
}

.gateway-error {
  border-color: #e63232;
  background-color: #e63232;
  span {
    color: #ffffff;
  }
}
