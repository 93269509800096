.hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    width: 0px;
  }
}

body {
  &::-webkit-scrollbar {
    width: 0px;
  }
}
