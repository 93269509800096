@import "../../../assets/scss/variables.scss";

.input-fieldset {
  padding: 1.35em 0.625em 0.75em;
  margin: 0 0.2rem;
  border: 1px solid $grey;
  border-radius: 10px;
  padding-bottom: 0;
  padding-top: 0;
  background: $white;
  transition: all 0.15s ease-in-out;

  > div {
    padding: 0.1rem 0;
  }

  .form-control:focus {
    border: none;
    box-shadow: none;
  }

  &:focus-within,
  &.input-fieldset-active {
    border-color: $primary;

    legend {
      color: $primary;
    }

    .input-prefix {
      color: $primary;
    }

    svg {
      color: $primary;
    }
  }

  .input-prefix {
    align-self: center;
    padding-left: 0.5rem;
    font-size: 1.2em;
    color: #d3d3df;
    transition: all 0.15s ease-in-out;
  }

  legend {
    width: initial;
    display: initial;
    font-size: 0.8em;
    padding: 0 0.5rem;
    border: none;
    margin: 0;
    color: $caption-text;
    transition: all 0.15s ease-in-out;
    margin-top: -0.5rem;
    background-color: white;
  }

  select {
    color: #495057 !important;
    font-family: "open sans";
    font-size: 14px;

    &:disabled,
    &[disabled],
    &[read-only] {
      background-color: $white;
      cursor: not-allowed;
    }
  }

  .input,
  input {
    border: none;
    padding: 0;
    padding-left: 0.5rem;
    margin-top: -0.2rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    transition: all 0.15s ease-in-out;
    font-size: 14px;
    font-family: "open sans";

    &:focus {
      color: $primary;
      caret-color: $primary;
    }

    &:disabled,
    &[disabled],
    &:read-only,
    &[read-only] {
      background-color: $white;
      cursor: not-allowed;
    }

    &:placeholder-shown {
      font-size: 14px;
    }

    &::placeholder {
      background: $white;
      font-family: "open sans";
    }
  }

  textarea.form-control {
    height: 72px;
  }

  &.error,
  &.error:focus-within {
    border-color: $danger;
    legend,
    .input-prefix {
      color: $danger;
    }

    .input:focus {
      color: $danger;
      caret-color: $danger;
    }
  }

  input.input::-webkit-input-placeholder {
    font-size: 0.9em !important;
    background-color: $white;
  }

  select {
    background: none;
    border: none;
    color: $caption-text;
    appearance: none;
    cursor: pointer;
    padding: 0 0.5rem;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  button {
    &:focus,
    &:active {
      outline-width: 0;
      box-shadow: none;
    }
  }
}

.cancellation-input .input-fieldset textarea.form-control {
  height: 150px;
}

.error-text {
  margin-left: 1.3rem;
  font-size: 0.8em;
  color: $danger;
}

.checkbox {
  margin: 0 0.5rem;
  span {
    font-size: 0.85em;
    margin-left: 1rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000 !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px#fff inset !important;
}
