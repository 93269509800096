.grey-bg {
  background-image: url('../../assets/img/others/backgroundLight.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #eeeff6 !important;
}

.yedpay-logo {
  margin-top: 5rem;
}

.fixed-lang-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
