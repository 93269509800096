.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  &::after {
    content: '';
    padding: 1rem;
    flex: 1;
    min-width: 250px;
  }

  .custom-card {
    flex: 1;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid $grey;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    min-width: 250px;

    @include media-breakpoint-up(md) {
      min-height: 240px;
    }

    &.place-holder {
      border-style: dashed;
      min-height: auto;
      * {
        margin: auto;
      }
    }

    .custom-card-header {
      text-align: center;
      padding-bottom: 1rem;

      * {
        display: block;
        margin: auto;
      }
      img {
        width: 107px;
        height: 107px;
        object-fit: cover;
        border-radius: 100%;
        padding: 1px;

        &.no-rounded {
          border-radius: 0 !important;
        }
      }
      span {
        font-size: 1.3em;
      }
      p {
        font-size: 0.8em;
      }
    }

    .custom-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .custom-card-footer {
      margin-top: 1rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      button {
        padding: 0;
      }
    }
  }
}
