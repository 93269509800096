@import '../../../assets/scss/variables.scss';
.box-header {
  max-width: 747px;
  background-repeat: no-repeat;
  background-size: 100%;
  &.header-company-info {
    background-image: url('../../../assets/img/admin_headers/company_info_header.png');
  }
  &.header-payplus {
    background-image: url('../../../assets/img/admin_headers/company_info_header.png');
    background-size: cover;
    @media screen and (min-width: 576px) {
      max-width: 100%;
    }
  }
  &.header-stores {
    background-image: url('../../../assets/img/admin_headers/stores_header.png');
  }
  &.header-users-and-roles {
    background-image: url('../../../assets/img/admin_headers/users_header.png');
  }
  &.header-bank-accounts {
    background-image: url('../../../assets/img/admin_headers/bank_header.png');
  }

  .header-container {
    padding: 0 1rem;
    padding-top: 4rem;
    .header-text {
      p {
        width: 70%;
        font-size: 0.8em;
      }
    }
  }
}

.box {
  margin: 0 auto;
  max-width: 747px;
  &.payplus {
    @media screen and (min-width: 576px) {
      max-width: 90%;
    }
  }

  .box-icon {
    width: 41px;
    height: 41px;
  }

  .box-field-title {
    width: 100%;
    word-wrap: break-word;
    h4 {
      font-size: 1.2em;
    }
    p,
    button {
      font-size: 0.8em;
    }
  }

  .document {
    min-height: 82px;
    border: 1px solid $grey;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0.5rem;

    span {
      font-size: 0.7em;
    }

    .doc-info {
      display: flex;
      width: 60%;
      justify-content: space-around;

      span {
        font-family: 'Colfax-Regular';
        color: $caption-text;
      }
    }
  }
}
