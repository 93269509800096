@import '../../../assets/scss/variables.scss';

.yedpay-side-bar {
  width: 14rem;
  margin: 1rem;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  img {
    width: 9%;
    height: 9%;
    margin-right: 0.8rem;
  }

  @include media-breakpoint-only(xs) {
    width: 100%;
    height: auto;
  }
}
