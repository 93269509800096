.link {
  display: block;
  transition: all 0.15s ease-in-out;
  margin-bottom: 0.5rem;
  padding: 0 20px;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.05);
  }

  &:last-child img {
    padding: 0;
  }
}

.route {
  display: flex;
  align-items: center;
  height: 55px;
  transition: filter 0.15s ease-in-out;

  img {
    width: 38px;
    height: 38px;
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    filter: brightness(1.2);
    img {
      transform: scale(1.2);
    }
  }
}
