img {
  -webkit-user-drag: none !important;
}

.circle {
  width: 107px;
  height: 107px;
  object-fit: cover;
  border-radius: 100%;
}
.circle-sm {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 100%;
}

@for $i from 1 through 100 {
  .img-#{$i}p {
    width: $i * 1%;
  }
  .img-#{$i}px {
    width: $i * 1px;
  }
}

.img-lg {
  width: 180px;
}
.img-sm {
  width: 100px;
}
.img-xs {
  width: 50px;
}
