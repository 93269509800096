@import '../../../assets/scss/variables';

.form-header {
  // margin-top: 18px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  img {
    margin-right: 1rem;
  }

  h1 {
    font-family: 'open sans';
    font-size: 2.1em;
    font-weight: 100;
  }

  // &.form-header-caption {
  //   margin-top: 8px;
  //   color: $caption-text;
  // }

  // &.form-header-caption-red {
  //   margin-top: 8px;
  //   color: #c84744 !important;
  //   font-size: 0.8em;
  // }
}
.form-header-caption {
  margin-top: 0px;
  color: $caption-text;
  font-size: 0.9em;
}

.form-header-caption-red {
  margin-top: 0px;
  color: #c84744 !important;
  font-size: 0.9em;
}
