.round-lg {
  border-radius: $border-max-radius;
}
.round-sm {
  border-radius: $border-min-radius;
}

.round-bottom-lg {
  border-radius: 0 0 $border-max-radius $border-max-radius;
}

.round-bottom-sm {
  border-radius: 0 0 $border-min-radius $border-min-radius;
}

.round-top-lg {
  border-radius: $border-max-radius $border-max-radius 0 0;
}

.round-top-sm {
  border-radius: $border-min-radius $border-min-radius 0 0;
}

.dash-border {
  border-style: dashed !important;
}

.border-active-success {
  border: 2px solid $success;
}
.border-active-primary {
  border: 2px solid $primary;
}
