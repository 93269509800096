@import "./variables";

.outline {
  background-color: transparent;

  &.outline-blue {
    border: 1px solid $primary;
  }
}

.text-blue {
  color: $primary;
}

.btn-hover:hover {
  background-color: $box-shadow;
}

button[disabled] {
  cursor: not-allowed !important;
}
