@import '../../../assets/scss/variables.scss';

#gateway-content {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  .main-content {
    padding-bottom: 1rem;
  }
}

// .editAndTermsButtons {
//   box-sizing: border-box;
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

.gatewayList {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 3rem;

  &.padding-last::after {
    width: 290px;
    content: '';
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
    column-gap: 2rem;
  }
}

.gateway-card {
  min-height: 400px;
  position: relative;
  display: flex;
  justify-content: center;

  .gateway-card-header {
    top: 0;
    width: 290px;
    height: 70px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 290px;
  }
  .gateway-card-body {
    height: 100%;
    width: 290px;
    background-color: white;
    border-radius: 0 0 8px 8px;
    box-shadow: 5px 4px 10px $box-shadow;
    padding: 1rem 1.3rem;
    padding-top: 57px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    p {
      word-break: break-word;
    }
  }

  div.btn-outline-success {
    cursor: default;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: white !important;
      color: $success !important;
    }
  }
}

// The reason why not to use btn-group from bootstrap
// is because the text in the primary btn will not be
// centered
// that's why the follows come in
.applied-btn-group {
  border-radius: 0.25rem;
  // overflow: hidden;
  position: relative !important;

  .configure-btn {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute !important;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.small-btn-container{
  padding: 0.375rem 0;
  border: 1px solid transparent;
  a {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}
