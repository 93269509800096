.scrollable {
  max-height: 50vh;
  overflow-x: hidden;
}

@for $i from 1 through 10 {
  $base: 10vh;
  $vh: $base * $i;
  .scrollable-#{$i} {
    max-height: $vh;
    overflow-x: hidden;
  }
}
