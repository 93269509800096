.searchBar {
  background-color: white;
  flex-basis: 200%;
  padding: 1rem;
  margin-bottom: 1rem;

  fieldset {
    margin-top: 0 !important;
  }
}
