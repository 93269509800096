@import '../../../assets/scss/variables.scss';

.yedpay-navbar {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;

  @include media-breakpoint-up(lg) {
    display: flex;
    position: relative;
    width: 300px;
  }
}

.show-menu {
  @include media-breakpoint-down(md) {
    .yedpay-navbar {
      width: 200px;
    }
    #main-container {
      margin-left: 200px;
    }
    #menu-overlay {
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 1039;
      transition: background-color 0.5s;
    }
  }
}

.navlink-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  @include media-breakpoint-up(lg) {
    width: 300px;
  }
}

#main-container {
  transition: margin-left 0.5s;
  padding: 0 16 16 16;
  position: fixed;
  width: 100%;
  @include media-breakpoint-up(lg) {
    position: relative;
  }
}
