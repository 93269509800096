@import '../../../assets/scss/variables.scss';

.text-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $box-shadow;
  padding-bottom: 0.7rem;
  word-break: break-all;

  span {
    font-size: 0.9em;
    font-family: 'Colfax-Regular';
    color: $caption-text;
    white-space: nowrap;
    margin-right: 1rem;
  }

  p {
    text-align: right;
    font-size: 0.9em;
    margin: 0;
  }
}
