@for $i from 1 through 20 {
  .delay-#{$i} {
    animation-delay: 0.2s * $i;
  }
}

// Changing the default settings in animate.css
// the default property is affecting the dropdown (bootstrap)
// which will see through the elements under the dropdown menu
// this will fix the issue
// @laudirbispo from github
// https://github.com/animate-css/animate.css/issues/596
.animated-mode-none {
  animation-fill-mode: none !important;
}
