// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// 2. Include any default variable overrides here
$min_width: 320px;
$max_width: 1200px;
$min_font: 14px;
$max_font: 22px;

$link-decoration: none;
$link-hover-decoration: none;

$screenloader-bgc: rgba(121, 119, 119, 0.26);
$loader-bgc: #f3f3f4;

// $primary: #4d7bf3;
$primary: #4a72b5;
// $primary: red;
$grey: #d3d3df;
$blue: #4a72b5;
// $blue: #4d7bf3;
$success: #2fcc71;
$light: rgba(227, 227, 228, 0.43);

$bg-light-white: #f9fbfc;

$dashboard-bg: #0e1b38;

$danger: #c84744;
$warning: #ffbb00;
$caption-text: #9092a5;
$text: #0e1823;

$border-min-radius: 8px;
$border-max-radius: 10px;

$box-shadow: rgba(227, 227, 228, 0.43);

$custom-colors: (
  "primary": #4d7bf3,
  "info": #56ccf2,
  "success": #2fcc71,
  "warning": #f2c94c,
  "danger": #eb5757,
  "light": #bdbdbd
);
$theme-colors: map-merge($theme-colors, $custom-colors);

