.filter-box {
  background-color: white;
  border-radius: 5px;
}

.transaction-content {
  background: white;
  padding-left: 10px;
  padding-right: 10px;

  h4 {
    font-weight: 600;
    color: #676a6c;
    font-size: 14px;
    line-height: 1.3;
    font-family: 'colfax-regular';

    @media screen and (max-width: 756px) {
      font-size: 12px;
    }
  }
}

.transaction-status-logo {
  width: 40%;
}
.refund-amount {
  font-family: 'colfax-regular';

  font-size: 2.5rem;

  span {
    font-size: 2rem;
  }
}

.transaction-details {
  font-family: 'colfax-regular';
  font-size: 15px;
}
