.bank-ref-table {
  thead {
    tr {
      th {
        margin: 1rem;
        box-shadow: 0 5px 3px -1px #f2f3f8;
        border-bottom: 0;
        white-space: nowrap;
        font-weight: 600;
        color: #676a6c;
        font-size: 14px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-weight: 600;
        color: #676a6c;
        font-size: 14px;
      }
    }
  }
}

.bank-ref-table-title {
  font-weight: 600;
  color: #676a6c;
  font-size: 14px;
}
