@import "../../../assets/scss/variables.scss";

.table-responsive,
.custom-table {
  background: #fff;
  padding: 1rem;

  border-radius: 10px;

  // overflow: inherit !important;
  width: 100%;

  .table {
    td,
    th {
      background-color: transparent;
    }

    td {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      &,
      * {
        white-space: nowrap;
        vertical-align: middle;
      }
    }

    thead {
      th {
        font-weight: normal;
        color: #9092a5;
        margin: 1rem;
        box-shadow: 0 5px 3px -1px #f2f3f8;
        border-bottom: 0;
        white-space: nowrap;
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .pagination-wrapper {
      display: flex;
      justify-content: center;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      .pagination {
        margin: 0 auto;
      }
    }

    .custom-page-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: baseline;
      padding: 0 1rem;
      .custom-page-number {
        width: 3.5rem;
        height: 100%;
        @include media-breakpoint-only(xs) {
          width: 35px;
        }
        input {
          text-align: center;
          padding: 0 2px;
          color: #4d7bf3;
        }
      }
      .custom-page-desc {
        white-space: nowrap;
      }
    }

    .pagination-desc {
      padding-right: 0.75rem;
      text-align: center;
    }
  }
}

.custom-table {
  .td {
    padding: 0.5rem 1rem;
  }
  .custom-table-header {
    padding: 0.5rem 1.5rem;
    background-color: $white;
    box-shadow: 0 20px 3px -3px #f2f3f8;
    border-radius: 10px 10px 0 0;

    .td:not(:first-child) {
      border-left: 1px solid $box-shadow;
    }
  }

  .custom-table-body {
    padding: 0.5rem 1.5rem;
    background-color: $white;
    border-radius: 0 0 10px 10px;
    max-height: 550px;
    overflow-y: scroll;

    .tr:not(:first-of-type) {
      border-top: 1px solid $box-shadow;
    }

    .tr {
      .td {
        padding: 1rem;
      }
    }
  }

  .custom-table-hover {
    .tr {
      &:hover {
        background-color: $box-shadow;
      }
    }
  }

  .custom-table-no-data {
    min-height: 50vh;
    background-image: url("../../../assets/img/table/data_not_found.png");
  }
}
