
@font-face {
  font-family: 'Colfax-Medium';
  src: url('../fonts/Colfax-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Colfax-Regular';
  src: url('../fonts/Colfax-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Colfax-Bold';
  src: url('../fonts/Colfax-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CircularStd-Medium';
  src: url('../fonts/CircularStd-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import './fluid-font-size.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

html {
  font-size: calc(0.5em + 0.45vw);
  // @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

@for $i from 1 through 100 {
  .fx-#{$i} {
    font-size: 1px * $i !important;
  }
}

body {
  font-family: 'Colfax-Medium' !important;
}

small {
  font-family: 'Colfax-Regular';
}

button {
  font-size: inherit;
}
input {
  font-size: inherit;
}

.grey-text {
  color: $caption-text;
}

.date-text {
  font-family: 'Colfax-Regular';
}

.colfax-regular {
  font-family: 'Colfax-Regular';
}
.colfax-bold {
  font-family: 'Colfax-Bold';
}
.colfax-medium {
  font-family: 'Colfax-Medium';
}
.open-sans-regular {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.open-sans-light {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.amount {
  &::before {
    content: '$';
  }
  &::after {
    content: attr(data-currency);
  }
}

.display-time-txt {
  font-size: 15px;
}