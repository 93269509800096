.front {
  height: 796px;
  background-color: #4a72b5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  position: relative;

  color: white;

  user-select: none;

  .qr-container {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .qr-content {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 3.2rem 0;
      h1 {
        margin: 0;
        font-size: 2.5rem;
        font-family: "colfax-regular";

        margin-right: 1rem;
      }

      img {
        width: 25%;
      }
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 199;
    }

    .canvas-container {
      top: 2rem;
      position: absolute !important;
    }

    .qr-code {
      text-align: center;
      background-color: white;
      padding: 1.5rem;
    }

    .qr-code-dynamic {
      text-align: center;
      background-color: white;
      padding: 1.5rem;
      max-width: 350px;
      @media screen and (max-width: 767.98px) {
        max-width: 330px;
      }
    }

    .print_gateway {
      height: 30px !important;
    }
  }

  .qr-footer {
    width: 100%;
    height: 25%;
    background-color: #0f1c39;
    padding: 2rem;

    text-align: center;

    .yedpay-print-logo {
      width: 60%;
    }
  }
}
