@import '../../../assets/scss/variables.scss';
.rule {
  display: flex;
  align-items: baseline;
  margin-left: 1rem;

  img {
    width: 0.6rem;
    margin-right: 0.3rem;
  }

  span {
    color: $secondary;
    font-size: 0.9em;
  }
}
