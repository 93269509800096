@import '../../../assets/scss/variables.scss';

.yedpay-nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 1rem;
  }

  img {
    max-width: 70%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  h1 {
    font-size: 1.8em;
  }
}
