@import '../../../assets/scss/variables.scss';

.apply-gateway-modal {
  h3,
  p {
    color: $caption-text;
  }

  .gateway-info {
    .gateway-img {
      width: 40%;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.75em;
      font-weight: 300;
    }
  }

  .hr-line-dashed {
    border-style: dashed;
  }

  h3 {
    font-size: 0.9em;
    font-weight: 500;
  }

  .apply-visa-step {
    height: 40px;
    display: flex;
    align-items: center;
    a.download-link {
      text-decoration: underline;
    }

    &,
    a {
      font-size: 0.9em;
    }
  }
  .apply-visa-step::before {
    content: attr(data-step);
    height: 40px;
    min-width: 40px;
    color: #fff;
    background-color: $caption-text;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    margin-right: 14px;
  }

  .checkbox {
    label {
      span {
        font-size: 0.85em;
        font-family: 'Colfax-Regular';
        color: $caption-text;
      }
      input {
        margin-right: 1rem;
      }
    }
  }
}
