@import '../../assets/scss/variables.scss';

body {
  background-color: $bg-light-white !important;
}

#main-container {
  height: 100%;
  overflow: hidden;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;

  @include media-breakpoint-only(xs) {
    overflow-y: scroll;
  }
}

#main-content {
  flex: 1;
  height: calc(100vh - 5rem);
  overflow-y: scroll;

  @include media-breakpoint-only(xs) {
    flex: inherit;
    order: 2;
    height: auto;
  }
  @include media-breakpoint-up(lg) {
    flex: 2;
  }
}

#side-content {
  width: 250px;
  height: calc(100vh - 5rem);
  overflow: hidden scroll;
  margin-left: 20px;

  @include media-breakpoint-only(xs) {
    flex: 1;
    margin-left: 0;
    flex: inherit;
    width: inherit;
    height: auto;
  }
  @include media-breakpoint-up(lg) {
    width: 350px;
  }
}

.balance-box {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 1rem;
  overflow-x: auto;

  label {
    flex: 1;
    white-space: nowrap;
    padding-right: 1rem;
    margin: 0;
  }
  .balance {
    font-size: 2rem;
  }

  .break {
    width: 100%;
  }
}
