@import '../../../assets/scss/variables';

.topHeader {
  background-color: $bg-light-white !important;
  display: flex;
  flex-basis: 101%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: auto;

  position: sticky;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  top: 0;
  z-index: 10;
}

.header-title {
  order: 0;
  h1 {
    font-size: 1.9em;
  }

  small {
    color: $caption-text;
  }
}

.header-lang {
  order: 2;
}
.header-logout {
  order: 3;
}
.header-btn {
  order: 1;

  @include media-breakpoint-only(xs) {
    margin: 0.5rem 0;
    width: 100%;
    order: 4;
    display: flex;

    button {
      flex: 1 1 50%;
    }
  }
}
